@import "src/lib/styles/fivef/mixins/truncate";
@import "src/lib/styles/fivef/mixins/action_footer";

.cdk-overlay-container {
  z-index: map-get($z-index-set, z-modal) !important;

  .fivef-context-sidebar-overlay {
    .mat-mdc-dialog-container {
      box-shadow: none !important;
    }
  }
}

/**
 * Sidebar base setup.
 * Fixed at right, full height.
 */
.fivef-context-sidebar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  position: fixed;
  right: 0;
  left: auto;
  top: 0;
  bottom: 0;
  width: 798px;
  height: 100%;
  max-width: 100%;

  overflow: hidden;

  background: var(--fivef-color-surface-primary);
  box-shadow: 0px 0px 3rem 0px rgba(0, 0, 0, 0.288);
  border-left: 10px solid $dvtx-fivef-blue-400;

  /**
   * Sidebar content setup.
   * Uses flex with header/footer fixed and scrollable content in the middle.
   */

  /**
   * Header section.
   * Show title/subtitle left, right navigation, flex, space-between (full width).
   * Trigger text truncation if too long.
   */
  &--header-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    padding: var(--fivef-spacing-l) var(--fivef-spacing-l) 0;
  }

  /**
   * Next/previous navigation inside header right beside title.
   */
  &--nav {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  &--content-section {
    padding: 0 var(--fivef-spacing-l) var(--fivef-spacing-l) var(--fivef-spacing-l);
    display: flex;
    width: 100%;
    min-height: 2rem;
    flex-grow: 1;
    overflow: auto;
    flex-direction: column;

    .fr-popup {
      left: 212px !important;
    }
  }

  &--footer-section {
    @include fivef-action-footer();

    flex: 0 0 auto;
    padding-right: var(--fivef-spacing-s);
  }

  /**
   * Title/subtitle part of header with truncated titles if too long.
   * Main header is bold as before. Sub header is now an h3 with normal font weight.
   */
  &--header {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    flex-direction: column;

    &--title, &--subtitle {
      display: flex;
      flex-direction: row;
      font-size: 1.5rem;
      line-height: 1.1;

      @include fivef-text-truncate();
    }

    &--title {
      font-weight: 700;
    }

    &--icon {
      margin-right: var(--fivef-spacing-xs);
    }
  }
}

/**
 * Sidebar content directive.
 * Use full available space.
 * E.g. at Collecto attached to the tab group.
 */
.fivef-context-sidebar-content {
  height: 100%;
  width: 100%;

  /**
   * Slight padding on top below the tab bars.
   */
  .mat-mdc-tab-body-content {
    padding-top: var(--fivef-spacing-s);
    overflow-x: hidden;
  }

  /**
   * Height override for froala and the internal dialogs.
   * Displaces the editor in scrolling div, otherwise if no comments are present already.
   */
  .mat-mdc-tab-body-wrapper {
    height: inherit;
  }
}
