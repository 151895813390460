@import 'src/lib/styles/fivef/mixins/truncate';

/**
 * Content editable element styling.
 * Basic concept
 * The coloring must correspond to out mat-input overrides.
 * Biggest difference: Transparent default background.
 * - Show as normal text,
 * - highlight on hover,
 * - highlight stronger on activation.
 */
.fivef-input-ce {
  display: flex;
  min-height: 32px;
  align-items: center;

  &--input {
    margin: 0;
    outline: none !important;
    max-width: 100%;
    display: block;
    flex: 1 1 auto;
    min-height: inherit;

    // The styleing is oriented at our mat-input overrides.
    padding: var(--fivef-spacing-xxs) var(--fivef-spacing-s);
    background-color: transparent;
    transition: background-color .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: var(--fivef-border-radius-xs);

    &--field-background {
      background-color: var(--fivef-color-input-background-generic);
    }

    /**
     * Placeholder coloring.
     */
    &:empty:before {
      content: attr(data-ph);
      color: var(--fivef-color-input-placeholder);
      font-style: italic;
    }
  }

  &--text-truncate {
    @include fivef-text-truncate();
  }

  &--editable {
    background-color: var(--fivef-color-input-background);
  }

  &:hover {
    .fivef-input-ce--input {
      background-color: var(--fivef-color-input-background-hover);
      cursor: text;
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
