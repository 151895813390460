/**
 * Layout and flex convenience classes.
 */

/**
 * Full width convenience class (100%).
 */
.ff-w100 {
  width: 100%;
}

/**
 * Full height convenience class (100%).
 */
.ff-h100 {
  height: 100%;
}

/**
 * Simple common flex layout with elements at left and right side.
 */
.ff-space-between {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

/**
 * Simple common flex layout with elements at left and right side.
 */
.ff-end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

/**
 * Simple common flex layout with elements at left and right side.
 */
.ff-align-center {
  display: flex;
  align-items: center;

  h1, h2, h3, h4, h5 {
    margin-bottom: 0;
  }
}

/**
 * Centered layout: Text or item centered in full width and height container.
 */
.ff-centered {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * Centered row layout:
 * Text or item centered in full width container.
 */
.ff-centered-row {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * d-flex column layout.
 */
.ff-column {
  display: flex;
  flex-direction: column;
}

/**
 * d-flex column layout.
 */
.ff-grow {
  display: flex;
  flex-grow: 1;
}

.ff-row {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  gap: var(--fivef-spacing-m);
  padding: var(--fivef-spacing-s) 0;
}

.ff-wrap {
  flex-wrap: wrap;
}

/**
 * Convenience max-width 500px class for a typical
 * form setup on long fields (long input beside short one).
 */
.ff-max500 {
  max-width: 500px;
}

.ff-max600 {
  max-width: 600px;
}

.ff-max300 {
  max-width: 300px;
}

.ff-max200 {
  max-width: 200px;
}

.ff-max150 {
  max-width: 150px;
}
