/**
 * Activity log of Collecto and other BOM data node based workflows.
 */
.fivef-activity-log {
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}
