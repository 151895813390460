/**
 * Process event log or activity log of timeline, tabs and the favorite event menu.
 */
.ff-timeline-event {
  display: flex;

  &--performer {
    flex: 0 0 40px;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  &--content {
    border-left: 2px solid var(--fivef-timeline-separator-color);
    padding: 5px 15px;
    line-height: 1;
    flex: 0 0 calc(100% - 40px - 1rem);
    width: calc(100% - 40px - 1rem);
    margin-left: 1rem;

    .mat-icon {
      margin-right: 1rem;
      color: #7b8490;
    }
  }

  &--created-at {
    display: flex;
    flex-wrap: wrap !important;
    text-align: left;
    width: calc(100% - 24px - 1rem);

    > span {
      white-space: nowrap;
    }
  }

  &--custom {
    margin-left: 59px;
    border-left: 2px solid var(--fivef-timeline-separator-color);
    padding: 0 15px;
    margin-bottom: 1rem;
    margin-top: -1rem;

    &:last-child {
      margin-bottom: 0 !important;
    }

    &--content {
      padding: .5rem 1rem;
      background: var(--fivef-timeline-content-bg-color-light);
      text-align: left;

      > p {
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
