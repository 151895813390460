.fivef-paperbird-theme {
  $theme-link-hover-color: #e4732b;
  $theme-color-action: #e4732b;

  --fivef-paperbird-primary: #e4732b;
  --fivef-paperbird-highlight: var(--fivef-paperbird-primary);
  --fivef-paperbird-gray: #74767a;

  /**
   * Main action color / primary color.
   */
  --fivef-color-action: var(--fivef-paperbird-primary);
  --fivef-color-button-primary: var(--fivef-paperbird-primary);

  /**
   * Page header.
   */
  --fivef-page-header-link-box-shadow: var(--fivef-color-action);
  --fivef-app-menu-item-background-color-hover: var(--fivef-color-action);

  /**
   * Dashboard calendar
   */
  --fivef-dashboard-widget-colored-header-primary: var(--fivef-paperbird-primary);
  --fivef-dashboard-widget-colored-header-secondary: var(--fivef-paperbird-gray);

  /**
   * Sidebar.
   */
  --fivef-nav-link-color-current: var(--fivef-color-action);
  --fivef-nav-link-border-color-current: var(--fivef-color-action);
  --fivef-nav-link-color-hover: var(--fivef-color-action);
  --fivef-nav-link-border-color-hover: var(--fivef-color-action);
  --fivef-nav-link-border-color-active: var(--fivef-color-action);
  --fivef-color-sidebar-resize-handle: var(--fivef-color-action);

  /**
   * Link setup.
   */
  --five-f-link-color: var(--fivef-paperbird-primary);
  --five-f-link-color-hover: var(--fivef-paperbird-primary);

  --fivef-indicator-background-color-unread: #760000;
  --fivef-task-indicator-background-color-unread: #760000;
  --fivef-task-indicator-color-unread: white;

  --five-f-filter-beta-tag-background-color: var(--fivef-paperbird-primary);
  --five-f-filter-badge-background-color: var(--fivef-paperbird-primary);

  --five-f-filter-button-background-color-active: rgb(118, 0, 0);

  /**
   * Button color hover override
   */
  --five-f-icon-button-color-hover: var(--fivef-paperbird-primary);

  /**
   * Upload override.
   */
  //--fivef-upload-color: #e5f5fc;
  //--fivef-upload-background-color: #ccedf0;
  //--fivef-upload-border-color: #e5f5fc;
  //
  //--fivef-upload-color-hover: var(--fivef-color-text-tertiary);
  //--fivef-upload-background-color-hover: #ccedf0;
  //--fivef-upload-border-color-hover: #33b9c4;
  //
  //--fivef-upload-button-color: var(--fivef-color-text-secondary);
  //--fivef-upload-button-background-color: var(--fivef-color-action-highlight);
  //--fivef-upload-button-border-color: var(--fivef-color-border-primary);
  //
  //--fivef-upload-button-color-hover: white;
  //--fivef-upload-button-background-color-hover: #33b9c4;
  //--fivef-upload-button-border-color-hover: transparent;

  /**
   * Base tokens.
   */
  --fivef-color-action-highlight: var(--fivef-paperbird-highlight);

  --fivef-color-surface-private: var(--fivef-paperbird-gray);
  --fivef-color-surface-private-highlight: var(--fivef-paperbird-gray);
  /**
   * Menu items.
   */

  /**
   * Avatar overrides.
   */
  --fivef-avatar-color-background: var(--fivef-paperbird-gray);
  --fivef-avatar-color-border: var(--fivef-paperbird-gray);

  /**
   * Link setup.
   */
  --fivef-link-color: var(--fivef-color-action);
  --fivef-link-color-hover: #{$theme-link-hover-color};
  --fivef-link-text-decoration: none;
  --fivef-link-text-decoration-hover: underline;

  /**
   * Process breadcrumps
   */
  --fivef-process-path-breadcrumps-color: var(--fivef-color-action);
  --fivef-process-path-breadcrumps-icon-color: var(--fivef-color-text-secondary);
  --fivef-process-path-breadcrumps-color-hover: #{$theme-link-hover-color};
  --fivef-process-path-breadcrumps-icon-color-hover: #{$theme-link-hover-color};
  --fivef-process-path-breadcrumps-text-decoration: none;
  --fivef-process-path-breadcrumps-text-decoration-hover: underline;

  /**
   * Loading indicator component.
   */
  --fivef-process-tree-color: inherit;
  --fivef-process-tree-text-decoration: none;
  --fivef-process-tree-font-weight: normal;
  --fivef-process-tree-color-hover: #{$theme-link-hover-color};
  --fivef-process-tree-text-decoration-hover: underline;
  --fivef-process-tree-font-weight-hover: normal;
  --fivef-process-tree-color-active: var(--fivef-color-action);
  --fivef-process-tree-text-decoration-active: none;
  --fivef-process-tree-font-weight-active: bold;

  /**
   * Settings panels.
   */
  --fivef-settings-panel-profile-default-background-color: var(--fivef-paperbird-gray);
  --fivef-settings-panel-profile-default-background-color-hover: var(--fivef-paperbird-highlight);
  --fivef-settings-panel-profile-user-background-color: var(--fivef-paperbird-gray);
  --fivef-settings-panel-profile-user-background-color-hover: var(--fivef-paperbird-highlight);
  --fivef-settings-panel-profile-tenant-background-color: var(--fivef-paperbird-gray);
  --fivef-settings-panel-profile-tenant-background-color-hover: var(--fivef-paperbird-highlight);
  --fivef-settings-panel-profile-tenant-color: white;
  --fivef-settings-panel-profile-tenant-color-hover: white;
  --fivef-settings-panel-border-radius: var(--fivef-border-radius-m);
  --fivef-settings-panel-box-shadow-hover: 0 16px 16px -16px #{$dvtx-cool-grey-300} !important;


  // --fivef-color-surface-primary-highlight: var(--fivef-color-action);

  // General overrides (order important)
  // 1. Base overrides
  // 2. Functionality overrides
  // 3. Module dependent overrides.
  @import "colors";
  @import "font";

  // Import 5F material overrides into namespace.
  @import "../../../override/index";
}
